@import 'helpers/Colors.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code
{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root
{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-container
{
  width: 1500px;
}

select
{

  border-radius: 5px;
  background: transparent;
  padding: 7px 12px;

  border: 2px solid var(--borderGrey);

  cursor: pointer;
  height: 32px;
}

select:focus
{
  border-color: var(--btnColor);
}

input, textarea
{
  border-radius: 10px;
  padding: 0.625rem;
  border: 2px solid var(--btnColor);
  outline: none;
}

input:focus, textarea:focus
{
  border-color: var(--btnColor);
}

.react-datepicker-wrapper
{
  display: block !important;
}

table
{
  display: flex;
  flex-direction: column;

  text-align: center;
  height: 100%;

  gap: 10px;
}

thead
{
  display: block;
  width: 100%;
}

tr
{
  display: flex;
  align-items: center;
  border-radius: .5rem;
  box-shadow: 0 0 10px rgba(212,216,232,.6);
}

thead tr th
{
  flex: 1;
  font-weight: 400;

  padding: 8px;
  box-sizing: border-box;
}

tbody
{
  display: flex;
  flex-direction: column;

  gap: 10px;
}

tbody tr
{
  display: flex;
  align-items: center;

  border-radius: .5rem;
  box-shadow: 0 0 10px rgba(212,216,232,.6);
  gap: 5px;

  padding: 8px;
}

tbody td
{
  flex: 1;

  padding: 5px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1318px), (min-device-width: 768px) and (max-device-width: 1318px)
{
  .main-container
  {
    width: auto;
  }
}
