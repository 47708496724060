.container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.container label
{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.password_container
{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.password
{
    display: flex;
    justify-content: center;
    gap: 10px;

    font-size: 24px;

    width: fit-content;
    min-width: 300px;
    height: 52px;

    padding: 10px;

    border: 2px solid var(--borderGrey);
    border-radius: 10px;

    box-sizing: border-box;
}

.password button
{
    all: unset;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
}

.password button:active
{
    transform: scale(0.95);
}

.password button:active svg
{
    color: var(--btnColor) !important;
}

.password button:hover svg
{
    color: rgba(0, 0, 0, .6);
}

