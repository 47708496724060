.notifications_provider_container
{
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
}

.notifications_container
{
    display: flex;

    flex-direction: column;
    position: fixed;

    gap: 20px;
    justify-content: center;
    align-items: center;

    pointer-events: none;

    margin-top: 50px;

    z-index: 10;
}
