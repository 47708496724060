.search
{
    display: flex;
    align-items: center;

    padding: 0.25rem 6px;
    gap: 8px;

    border: 2px solid var(--borderGrey);
    border-radius: 5px;

    width: 350px;
    height: 32px;

    box-sizing: border-box;
}

.search input
{
    all: unset;

    width: 100%;
}

.search:focus-within
{
    border-color: var(--btnColor);
}

.disabled
{
    background: var(--btnColorDisabled);
    border-color: var(--btnColorDisabled);

    cursor: default;

    pointer-events: none;
}
