.message_container
{
    display: flex;
    flex-direction: column;

    background: #fff;
    border-radius: 10px;

    overflow: hidden;

    animation: showMessage 1s ease forwards;

    pointer-events: auto;

    box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
}

.hide
{
    animation: hideMessage 1s ease forwards;
}

@keyframes showMessage
{
    0%
    {
        opacity: 0;
    }
    100%
    {
        opacity: 1;
    }
}

@keyframes hideMessage
{
    0%
    {
        opacity: 1;
    }
    100%
    {
        opacity: 0;
    }
}

.message_type
{
    width: 10px;
}

.error
{
    background: var(--error);
}

.warning
{
    background: var(--warning);
}

.success
{
    background: var(--success);
}

.message_content_container
{
    display: flex;
    gap: 5px;
}

.message_content
{
    display: flex;
    align-items: center;

    width: 350px;
    gap: 10px;
    padding: 0.625rem;
    font-size: 18px;

    word-break: break-word;
}

.message_type_icon, .close
{
    display: flex;
    align-items: center;
    height: 100%;
}

.close
{
    cursor: pointer;
}

.close:hover
{
    color: var(--btnColor);
}

.message
{
    display: flex;

    width: 100%;
    word-wrap: break-word;
}

.message_type_icon svg, .close svg
{
    width: 25px;
    height: 25px;
}

.message_timer
{
    width: 0;
    height: 5px;

    animation: timerAnimation 3s linear forwards;

}

@keyframes timerAnimation
{
    0%
    {
        width: 0;
    }
    100%
    {
        width: 100%;
    }
}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .message_content
    {
        width: 310px;
    }
}
